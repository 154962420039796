import React from 'react'
import HeaderBar from '../../components/header-bar'
import DisplayPicture from '../../components/ui/display-picture'
import { connect } from 'react-redux'
import { Dispatch } from 'redux';
import { User } from '../model/dto/res/user';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClose, faPencil, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { PlatformScreen, UserActionType } from '../redux/reducers/types';

interface Props {
    active_screen?: PlatformScreen;
    userloginDetails?: User;
    logout?: () => void;
    onClose: () => void;
    canShowProfile: boolean;
}

export const Profile = (props: Props) => {
    const navigation = useNavigate();

    const onLogout = () => {
        if (props.logout) {
            props.logout();
            navigation('/');
        }
    }

    const onCloseProfile = () => {
        const checkboxElement = document.getElementById('profile_toggler_checkbox');
        if (checkboxElement) {
            checkboxElement.click();
        }
    }

    return (
        <div className={`profile h-100 bg-light ${props.canShowProfile ? 'open' : 'close'} ${props.active_screen === PlatformScreen.IN_GAME ? 'no-header' : ''}`}>
            <label htmlFor="profile_toggler_checkbox" className='position-absolute' style={{ right: 0 }} >
                <FontAwesomeIcon onClick={props.onClose} icon={faClose} className={`${props.userloginDetails ? 'text-white' : 'text-dark'} close-btn`} ></FontAwesomeIcon>
            </label>
            {props.userloginDetails && <div className="">
                <div className="d-flex justify-content-center moplay-red-bg py-3 px-lg-5 px-3 text-white">
                    <div className=" text-end">
                        <DisplayPicture width={100} height={100} first_name={props.userloginDetails?.name || ''} last_name='' profile_pic={props.userloginDetails?.profile_img}></DisplayPicture>
                    </div>
                    <div className="mx-3 text-start">
                        <p>
                            {props.userloginDetails.name}
                            <br></br>
                            <small>{props.userloginDetails.email}</small>
                        </p>
                        <p>{props.userloginDetails.address}</p>
                        <Link onClick={props.onClose} to={'/user-info'}><button className="btn btn-primary px-4">Edit <FontAwesomeIcon icon={faPencil} className='ms-2'></FontAwesomeIcon></button></Link>
                    </div>
                </div>
                <div>
                    <ul className='options px-2 pt-2'>
                        <Link onClick={props.onClose} to={'/social'}> <li className='border'>Social <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                        <Link onClick={props.onClose} to={'/likes'}> <li className='border'>Liked Games <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                        <Link onClick={props.onClose} to={'/challenges'}> <li className='border'>Challenges <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                        <Link onClick={props.onClose} to={'/blog'}> <li className='border'>Blogs <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                        <Link onClick={props.onClose} to={'/contact'}> <li className='border'>Contact Us <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                        <li className='border' onClick={onLogout}>Logout <FontAwesomeIcon className='indicator' icon={faSignOut}></FontAwesomeIcon></li>
                    </ul>
                </div>
            </div>
            }
            {
                !props.userloginDetails && <ul className='options px-2 pt-5'>
                    <Link onClick={onCloseProfile} to={'/terms'}> <li className='border'>Terms & Conditions <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                    <Link onClick={onCloseProfile} to={'/privacy'}> <li className='border'>Privacy Policy<FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                    <Link onClick={onCloseProfile} to={'/contact'}> <li className='border'>Contact Us <FontAwesomeIcon className='indicator' icon={faAngleRight}></FontAwesomeIcon></li></Link>
                </ul>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user,
    active_screen: state.PlatformStore.active_screen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => { dispatch({ type: UserActionType.LOGOUT }) }
})
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
