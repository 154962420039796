import { faArrowRightFromBracket, faBars, faBell, faClock, faEnvelope, faHeart, faHouse, faShop, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, NavLink } from 'react-router-dom';
import UserProfileImage from './../assets/images/user-profile.jpeg';
import MoplayLogoImage from './../assets/images/icons/moplay.png';
import FormBuilder from './form-builder';
import { SIGNIN } from '../app/service/login.service';
import { GOOGLE_CLIENT_ID } from '../config/constant';
import GoogleIconImage from './../assets/images/google.png';
import { FormValidator } from './form-builder/validations';
import { FormControlError, FormField, FormValidators } from './form-builder/model/form-field';
import { GoogleLogin } from '@react-oauth/google';
import { UserService } from '../app/service/user.service';
import { jwtDecode } from 'jwt-decode';
import { connect } from 'react-redux';
import DisplayPicture from './ui/display-picture';
import { User } from '../app/model/dto/res/user';
import { PlatformScreen, PopupType } from '../app/redux/reducers/types';
import { Resources } from '../app/utility/resources';

interface Props {
    currentTime?: any;
    durationVideo?: any;
    sidemenuOfcanavsView?: any;
    activeMenu?: any;
    videoProgress?: any;
    userData?: any;
    userloginDetails?: any;
    popUpuserFlowAction?: any;
    loginStatus?: any;
    showLoginPopup?: () => void;
    mobileLoginStatus?: any;
    userdata?: any;
    userLoginStatus?: any;
    updateUserLogin?: (data: any) => void;
    updateUserLoginStatus?: (data: any) => void;
    updateMobileLoginStatus?: (data: any) => void;
    activeScreen: PlatformScreen;
}

const MobileViewMenu = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState(props?.currentTime || 0);
    const [duration, setDuration] = useState(props?.durationVideo || 0);
    const [userFlowAction, setuserFlowAction] = useState(0);
    const [isOffCanvasOpen, setIsOffCanvasOpen] = useState<boolean>(false);
    const [emailError, setEmailError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [userPasswordError, setUserPasswordError] = useState('');
    const [isUserFormSubmitted, setIsUserFormSubmitted] = useState(false);
    const [isLogin, setISLogin] = useState(false);
    const [isLoginButton, setISLoginButton] = useState(true);
    const [userPopUpFlowAction, setuserPopUpFlowAction] = useState<boolean>();
    const [isPopUpShow, setIsPopUpShow] = useState(false);
    const stopPropagation = (event: MouseEvent) => { event.stopPropagation(); }
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [loginDetails, setLoginDetails] = useState<any>({})
    const [userDetails, setUserDetails] = useState<User | null>(null);
    const [parentsData, setParentsData] = useState<any>({});
    const [userIdView, setUserIdView] = useState(false);
    const offcanvasRef = useRef<HTMLDivElement>(null);
    const progress = (currentTime / duration) * 100;
    const navigation = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('/home');

    useEffect(() => {
        if (props) {
            setCurrentTime(props?.currentTime);
            setDuration(props?.durationVideo);
        }
    }, [props])


    useEffect(() => {
        if (props?.userdata) {
            setUserDetails(props?.userdata);
            setISLogin(true);
        } else {
            setUserDetails(null);
            setISLogin(false);
        }
    }, [props.userdata])

    useEffect(() => {
        setuserPopUpFlowAction(props.popUpuserFlowAction);
    }, [userPopUpFlowAction,])

    const onMenuNavigation = (menu: string, shouldLogin: boolean) => {
        if (shouldLogin) {
            if (userDetails) {
                navigation(menu);
                setActiveMenuItem(menu);
            } else {
                if (props.showLoginPopup) {
                    props.showLoginPopup();
                }
            }
        } else {
            navigation(menu);
            setActiveMenuItem(menu);
        }
    }

    return (
        <div>
            {/* {props?.activeMenu === "home" && (
                <div className="d-md-none video-progress-bar" style={{ top: "auto", bottom: "55px", height: "3px" }}>
                    <div className="progress" style={{ width: `${props?.videoProgress}%` }}></div>
                </div>
            )} */}
            <div className="d-md-none position-relative">
                <div className={`mobile-navbar position-fixed fixed-bottom d-flex justify-content-between align-items-center border-top px-5 mx-2 transition-500ms ${props.activeScreen === PlatformScreen.IN_GAME ? 'to-bottom' : ''}`}>
                    <div onClick={() => onMenuNavigation('/home-page', false)} className={`${activeMenuItem === '/home-page' ? 'active' : ''} pointer d-flex flex-column align-items-center`} >
                        <FontAwesomeIcon fontSize={20} icon={faHouse} />
                        {/* <b className='f-14'>Home</b> */}
                    </div>
                    <div onClick={() => onMenuNavigation('/home', true)} className={`${activeMenuItem === '/home' ? 'active' : ''} pointer d-flex flex-column align-items-center`}>
                        <img src={Resources.GAMES_ICON} alt="" />
                        {/* <FontAwesomeIcon fontSize={20} icon={faUserPlus} /> */}
                        {/* <b className="text-center f-14">Social</b> */}
                    </div>
                    <div onClick={() => onMenuNavigation('/social', true)} className={`${activeMenuItem === '/social' ? 'active' : ''} pointer d-flex flex-column align-items-center`}>
                        <FontAwesomeIcon fontSize={20} icon={faUserPlus} />
                        {/* <b className="text-center f-14">Social</b> */}
                    </div>
                    {/* <NavLink to="/notifications" className='text-decoration-none d-flex flex-column align-items-center'>
                        <FontAwesomeIcon fontSize={20} icon={faBell} />
                    </NavLink> */}
                    {/* {userDetails && <NavLink to="/profile" className='text-decoration-none d-flex flex-column align-items-center'>
                        <DisplayPicture text_size={20} width={24} height={24} first_name={userDetails?.name || ''} last_name='' profile_pic={userDetails?.profile_img}></DisplayPicture>
                    </NavLink>} */}
                    {/* <div className='d-flex flex-column align-items-center' data-bs-toggle="offcanvasMobile" data-bs-target="#offcanvasMobileMenu" aria-controls="offcanvasMobileMenu" onClick={onSidemenuShow}>
                        <FontAwesomeIcon icon={faBars} />
                        <b className='f-14' >More</b>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    userdata: state.UserStore.user,
    userLoginStatus: state.UserStore.loginstatus,
    mobileLoginStatus: state.UserStore.mobileloginstatus,
    activeScreen: state.PlatformStore.active_screen
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserLogin: (data: any) => dispatch({ user: data, type: 'login' }),
    updateUserLoginStatus: (data: any) => dispatch({ loginstatus: data, type: 'loginstatus' }),
    updateMobileLoginStatus: (data: any) => dispatch({ mobileloginstatus: data, type: 'mobileloginstatus' }),
    showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' })
})

const connected = connect(mapStateToProps, mapDispatchToProps)(MobileViewMenu)
export { connected as MobileViewMenu };